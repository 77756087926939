














































import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  props: ["post"],
  data: () => ({
    professors: [] as any
  }),
  mounted: function () {
    this.getProfessorList();
  },
  methods: {
    getProfessorList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list-need", {
          collection: "staff",
          need: {
            type: "professor",
          },
        })
        .then((response) => {
          console.log("get response");
          this.professors = response.data;
          for(var i = 0; i<this.professors.length; i++){
            this.professors[i].photo = Vue.prototype.serverAddr + "/" + this.professors[i].photo;
          }
        });
    },
  },
});
